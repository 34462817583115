import React from "react"
import { graphql } from "gatsby"
import get from 'lodash/get'
import Layout from "../components/Layout"
import { GatsbySeo } from 'gatsby-plugin-next-seo';
import DateIcon from "../assets/svg/date.inline.svg"

class Etusivu extends React.Component {
  slider() {
    var slideIndex = 1;
    showSlides(slideIndex);
    var prev = document.getElementById('prev');
    var next = document.getElementById('next');
    prev.onclick = function(){ showSlides(slideIndex += -1); };
    next.onclick = function(){ showSlides(slideIndex += 1); };
    function showSlides(n) {
      var i;
      var slides = document.getElementsByClassName("card");
      var dots = document.getElementsByClassName("dot");
      if (n > slides.length) {slideIndex = 1}    
      if (n < 1) {slideIndex = slides.length}
      for (i = 0; i < slides.length; i++) {
        slides[i].style.display = "none";  
      }
      for (i = 0; i < dots.length; i++) {
        dots[i].className = dots[i].className.replace(" active", "");
      }
      slides[slideIndex-1].style.display = "block";  
      dots[slideIndex-1].className += " active";
    }
    // Swipe - touch devices
    var card = document.getElementsByClassName('content');
    for (var c = 0; c<card.length; c++) {
      var startx = 0
      card[c].addEventListener('touchstart', function(e){
        var touchobj = e.changedTouches[0]
        startx = parseInt(touchobj.clientX) 
      }, {passive: true})
      card[c].addEventListener('touchend', function(e){
        var touchobj = e.changedTouches[0]
        var dist = touchobj.clientX - startx
        if((startx > touchobj.clientX) && (dist < -50)) {
          showSlides(slideIndex += 1);
        }
        else if ((startx < touchobj.clientX) && (dist > 50) ) {
          showSlides(slideIndex += -1);
        }
      }, {passive: true})
    }

  }
  componentDidMount() {
    if(document.getElementById("slider") != null) {
      var cards = document.getElementById("slider").innerHTML;
      var new_html = `<a class="prev" id="prev" >&#10094;</a>${cards}<a class="next" id="next">&#10095;</a><div style="text-align:center" class="dots"><span class="dot"></span><span class="dot"></span><span class="dot"></span><span class="dot"></span><span class="dot"></span></div>`;
      document.getElementById("slider").innerHTML = new_html;
      this.slider();
    }
    //Href links scroller&list
    const kasino = get(this, 'props.data.allWpCptKasino.edges')
    kasino.map(({node}) => {
      var title = node.title;
      var nimi = document.getElementsByClassName('name');
      for(var t =0; t<nimi.length; t++) {
        if(title === nimi[t].innerHTML) { 
          nimi[t].parentElement.parentElement.nextElementSibling.children[1].href = `/kokemuksia/${node.slug}/`
        }
      }
      var linkki = document.querySelectorAll('.casinoRate .linkki');
      for(var v =0; v<linkki.length; v++) {
        var title2 = linkki[v].parentElement.previousElementSibling.children[1].children[0].children[0].innerHTML;
        if(node.title === title2) {
          linkki[v].href = `/kokemuksia/${node.slug}/`
        }
      }
    });
    if(document.querySelector('.entry-content .sections') != null) {
      document.querySelector('.entry-content .sections').remove();
    }
    if(document.querySelector('.entry-content .articles') != null) {
      document.querySelector('.entry-content .articles').remove();
      document.querySelector('.entry-content .articles-header').remove();
    }
  }
  render() {
  const page = get(this, 'props.data.page')
  const posts = get(this, 'props.data.posts')

  return (
    <Layout bodyClass={`page-template-default page wp-embed-responsive singular missing-post-thumbnail has-no-pagination not-showing-comments footer-top-visible customize-support`}>
      <GatsbySeo 
        title={page.seo.title} description={page.seo.metaDesc} 
        canonical={`https://www.vetoveljet.com/`}
      />
      <article className={`frontpage page type-page status-publish hentry`}> 
        <header className="front entry-header has-text-align-center header-footer-group">
          <div className="entry-header-inner section-inner medium">
            <h1 className="entry-title" dangerouslySetInnerHTML={{ __html: page.title }} />
          </div>
        </header>
        <div className="post-inner thin">
          <div className="entry-content" id="content" dangerouslySetInnerHTML={{ __html: page.content }} />
          <div className="sections">
            <h4>Tietoiskut</h4>
            <a href="/ilmaisvedot/" className="iconBox">
              <div className="box"><span className="icon" role="img" aria-label="ilmaisvedot">🎰</span></div>
              <span>Ilmaisvedot</span>
            </a>
            <a href="/online-betting/" className="iconBox">
              <div className="box"><span className="icon" role="img" aria-label="online">💻</span></div>
              <span>Online Betting</span>
            </a>
            <a href="/esports-vedonlyonti/" className="iconBox">
              <div className="box"><span className="icon"  role="img" aria-label="esports">🎮</span></div>
              <span>Esports vedonlyönti</span>
            </a>
            <a href="/kertoimet/" className="iconBox">
              <div className="box"><span className="icon" role="img" aria-label="kertoimet">✖</span></div>
              <span>Kertoimet</span>
            </a>
            <a href="/live-veto/" className="iconBox">
              <div className="box"><span className="icon" role="img" aria-label="live">💡</span></div>
              <span>Live-veto</span>
            </a>
            <a href="/riskiton-veto/" className="iconBox">
              <div className="box"><span className="icon" role="img" aria-label="riskiton">👍</span></div>
              <span>Riskitön veto</span>
            </a>
          </div>
          {posts.edges != null && <>
          <h3 className="articles-header"><center>Juuri julkaistua:</center></h3>
          <div className="articles">
            {posts.edges.map(({node})=>(
              <div key={node.slug} className="post">
                <a href={`/${node.slug}/`}>
                  {node.featuredImage != null && <img className="cardImg lazyload" src={node.featuredImage.node.mediaItemUrl} alt={node.featuredImage.node.title} width="310" height="140"/>}
                  <h4>{node.title}</h4>
                </a>
                <ul className="post-meta">
                  <li className="post-date"><DateIcon /> {node.date}</li>
                  <li>|</li>
                  <li className="post-cat">{node.categories.nodes.map((category, index) => (
                    <a href={category.uri} key={index} rel="category">{category.name}</a>
                  ))}</li>
                </ul>
                {node.ACFArtikkeliIntro.intro == null 
                ?<p className="text" dangerouslySetInnerHTML={{ __html: node.excerpt}} />
                :<p className="text" dangerouslySetInnerHTML={{ __html: node.ACFArtikkeliIntro.intro}} />
                }
                
              </div>
            ))}
            </div>
          </>}
        </div>
      </article>
    </Layout>
  )
  }
}
export default Etusivu

export const query = graphql`
query FrontPage {
    page: wpPage(title: {eq: "Vedonlyöntiä isolla telalla!"}) {
      id
      title
      content
      seo {
        metaDesc
        title
      }
      featuredImage {
        node {
          altText
          mediaItemUrl
          slug
        }
      }
    }
    allWpCptKasino {
      edges {
        node {
          id
          uri
          slug
          title
        }
      }
    }
    posts: allWpPost(limit: 3) {
      edges {
        node {
          id
          date(formatString: "DD.MM.YYYY")
          slug
          excerpt
          ACFArtikkeliIntro {
            intro
          }
          featuredImage {
            node {
              altText
              title
              mediaItemUrl
            }
          }
          categories {
            nodes {
              name
              uri
            }
          }
          title
        }
      }
    }
  }
`
